import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PaymentSuccess from './components/PaymentSuccess';
import FailurePage from './components/FailurePage';
import PendingPage from './components/PendingPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/success" element={<PaymentSuccess />} />
        <Route path="/failure" element={<FailurePage />} />
        <Route path="/pending" element={<PendingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
